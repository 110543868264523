import React, { useEffect, useState } from "react";
import Select from "react-select";

type Option = {
  label: string;
  value: string;
};

type MultiSelectInputProps = {
  options: Option[];
  selectedValues: Option[];
  onChange: (selected: Option[]) => void;
  placeholder?: string;
  enableAllOption?: boolean; // Allow "All" option
};

const MultiSelectInput: React.FC<MultiSelectInputProps> = ({ options, selectedValues, onChange, placeholder = "Select...", enableAllOption = true }) => {
  const [allSelected, setAllSelected] = useState(false);

  // Add "All" option if enabled
  const modifiedOptions = enableAllOption ? [{ label: "All", value: "all" }, ...options] : options;

  useEffect(() => {
    // Check if "All" should be selected
    if (selectedValues.length === options.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [selectedValues, options]);

  const handleChange = (selected: any) => {
    if (selected) {
      const isAllSelected = (selected as Option[]).some((opt) => opt.value === "all");

      // If "All" is selected, select all options
      if (isAllSelected) {
        if (allSelected) {
          onChange([]); // Deselect all
        } else {
          onChange(options); // Select all except "All"
        }
        setAllSelected(!allSelected);
      } else {
        // Handle normal selection
        onChange(selected);
      }
    } else {
      onChange([]);
    }
  };

  return (
    <Select
      isMulti
      value={allSelected ? modifiedOptions : selectedValues}
      options={modifiedOptions}
      onChange={handleChange}
      placeholder={placeholder}
      classNamePrefix="select"
    />
  );
};

export default MultiSelectInput;
