import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { useAtom } from "jotai";
import { selectedCurrencyAtom } from "../state/atoms";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Define the structure of the projection data
export interface DividendChartData {
  dt: string;
  symbol: string;
  dividend: number;
}

const StackedBarChart: React.FC<{ data: DividendChartData[] }> = ({ data }) => {
  const [currency] = useAtom(selectedCurrencyAtom);
  const [chartData, setChartData] = useState({
    labels: [] as string[],
    datasets: [] as { label: string; data: number[]; backgroundColor: string }[],
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: currency,
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
      datalabels: {
        display: true,
        // align: "end",
        // anchor: "end",
        formatter: (value: any, context: any) => {
          // Show label only for the total value (sum of all datasets for the month)
          const total: number = context.chart.data.datasets.reduce((acc: number, dataset: { data: number[] }) => {
            return acc + dataset.data[context.dataIndex];
          }, 0);

          // Show the label only for the last dataset (avoid repetition for every stacked entry)
          if (context.datasetIndex === context.chart.data.datasets.length - 1) {
            return new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
            }).format(total);
          } else {
            return null;
          }
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: false,
          text: "Month",
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Dividends (" + currency + ")",
        },
        beginAtZero: true,
      },
    },
  };

  // Function to return RGB codes for each symbol to be used as background color
  const getRandomColor = (symbol: string) => {
    let seed = ("SS" + (symbol || ""))
      .split("")
      .map((char) => char.charCodeAt(0))
      .reduce((a, b) => a + b, 0);
    const random = (seed * 9401 + 49297) % 233280;
    const r = ((random / 231280) * 256) | 0;
    const random2 = (seed * 49297 + 231280) % 49297;
    const g = ((random2 / 49297) * 256) | 0;
    const random3 = (seed * 231280 + 9301) % 9401;
    const b = ((random3 / 9401) * 256) | 0;
    return `rgba(${r}, ${g}, ${b}, 0.9)`;
  };

  useEffect(() => {
    const processData = (data: DividendChartData[]) => {
      const monthlyData: { [key: string]: { [key: string]: number } } = {};

      data.forEach(({ dt, symbol, dividend }) => {
        const month = new Date(dt).toLocaleString("default", { month: "long", year: "numeric" });

        if (!monthlyData[month]) {
          monthlyData[month] = {};
        }
        if (!monthlyData[month][symbol]) {
          monthlyData[month][symbol] = 0;
        }
        monthlyData[month][symbol] += dividend;
      });

      const labels = Object.keys(monthlyData);
      const symbols = Array.from(new Set(data.map((item) => item.symbol)));
      const datasets = symbols.map((symbol) => ({
        label: symbol,
        data: labels.map((month) => monthlyData[month][symbol] || 0),
        backgroundColor: getRandomColor(symbol),
      }));

      setChartData({
        labels: labels,
        datasets: datasets,
      });
    };

    processData(data);
  }, [data]);

  return (
    <div style={{ width: "100%" }}>
      <Bar data={chartData} options={options} plugins={[ChartDataLabels]} />
    </div>
  );
};

export default StackedBarChart;
