import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAtom } from "jotai";
import { customerIdAtom } from "../state/atoms";
import { tokenAtom } from "../state/atoms";
import { Form, Button, Table } from "react-bootstrap";
import MultiSelectInput from "./MultiSelectInput";
import Portfolio from "./Portfolio";

type Portfolio = {
  portfolio_id: string;
  portfolio_name: string;
};

type Symbol = {
  symbol: string;
  exchanges: string;
  portfolio_id: string;
  portfolio_name: string;
};

type XIRRResponseItem = {
  symbol?: string;
  cost_basis?: number;
  current_value?: number;
  unrlz_pnl?: number;
  unrlz_pnl_pct?: number;
  realized_pnl?: number;
  dividends?: number;
  hld_days?: number;
  arr_pct?: number;
  currency?: string;
};

const XIRRCalculator: React.FC = () => {
  const [customerId] = useAtom(customerIdAtom);
  const [token] = useAtom(tokenAtom);
  const [portfolios, setPortfolios] = useState<Portfolio[]>([]);
  const [selectedPortfolios, setSelectedPortfolios] = useState<Portfolio[]>([]);
  const [symbolsToExclude, setSymbolsToExclude] = useState<Symbol[]>([]);
  const [availableSymbols, setAvailableSymbols] = useState<Symbol[]>([]);
  const [includeClosed, setIncludeClosed] = useState(true);
  const [xirrResult, setXIRRResult] = useState<XIRRResponseItem[]>([]);
  const [includeInterests, setIncludeInterests] = useState(false);
  const [interests, setInterests] = useState<number>(0);
  const [applyTax, setApplyTax] = useState(false);
  const [currency, setCurrency] = useState("USD");

  // Fetch portfolios
  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${token.accessToken}` },
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${customerId}/portfolios`, config)
      .then((response) => {
        setPortfolios(response.data);
      })
      .catch((error) => console.error("Error fetching portfolios", error));
  }, [customerId]);

  // Fetch symbols when portfolios are selected
  useEffect(() => {
    const fetchSymbols = async () => {
      const config = {
        headers: { Authorization: `Bearer ${token.accessToken}` },
      };
      const allSymbols: Symbol[] = [];
      for (const portfolio of selectedPortfolios) {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/${customerId}/portfolio/${portfolio.portfolio_id}/symbols`, config);
        allSymbols.push(...response.data);
      }
      setAvailableSymbols(allSymbols);
    };
    if (selectedPortfolios.length > 0) {
      fetchSymbols();
    } else {
      setAvailableSymbols([]);
    }
  }, [selectedPortfolios, customerId]);

  const handleCalculateXIRR = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${customerId}/xirr`, {
        headers: { Authorization: `Bearer ${token.accessToken}` },
        params: {
          portfolio_names: selectedPortfolios.map((portfolio) => portfolio.portfolio_name).join(","),
          symbols_to_exclude: symbolsToExclude.map((symbol) => symbol.symbol).join(","),
          include_closed_positions: includeClosed,
          include_interests: includeInterests,
          apply_taxes: applyTax,
          currency: currency,
        },
      })
      .then((response) => {
        var dta = response.data;
        // Sort dta by (symbol == "-All-" ? 1 : 2), (cost_basis ? 1 : 2), arr_pct descending
        dta.sort((a: XIRRResponseItem, b: XIRRResponseItem) => {
          if (a.symbol === "-All-" && b.symbol !== "-All-") return -1;
          if (a.symbol !== "-All-" && b.symbol === "-All-") return 1;
          if (a.cost_basis && !b.cost_basis) return -1;
          if (!a.cost_basis && b.cost_basis) return 1;
          if ((a.arr_pct || 0) > (b.arr_pct || 0)) return -1;
          if ((a.arr_pct || 0) < (b.arr_pct || 0)) return 1;
          return 0;
        });
        // Also calculate unrlz_pnl_pct
        var interestsSum = 0;
        for (var i = 0; i < dta.length; i++) {
          if (dta[i].cost_basis && dta[i].current_value) {
            dta[i].unrlz_pnl_pct = ((dta[i].current_value - dta[i].cost_basis) / dta[i].cost_basis) * 100;
          }

          if (dta[i].symbol === "-CASH-") {
            interestsSum += dta[i].dividends || 0;
          }
        }

        dta = dta.filter((item: XIRRResponseItem) => item.symbol !== "-CASH-");
        setXIRRResult(dta);
        setInterests(interestsSum);
      })
      .catch((error) => console.error("Error calculating XIRR", error));
  };

  const colorShade = (arr_pct?: number, opacity?: string) => {
    if (!arr_pct) return "#FFFFFF" + (opacity || "80");
    // Create color shade betwene dark red and dark green based on ARR % (-100 to +100 capped)
    var color = "#";
    var r = 0;
    var g = 0;
    var b = 0;
    if (arr_pct < -100) arr_pct = -100;
    if (arr_pct > 100) arr_pct = 100;
    if (arr_pct < 0) {
      r = 255;
      g = 255 + arr_pct * 2.55;
    } else {
      g = 255;
      r = 255 - arr_pct * 2.55;
    }
    r = Math.round(r);
    g = Math.round(g);
    b = Math.round(b);
    color += r.toString(16).padStart(2, "0");
    color += g.toString(16).padStart(2, "0");
    color += b.toString(16).padStart(2, "0");
    return color + (opacity || "80");
  };

  return (
    <div className="container">
      {/* Row for Inputs */}
      <div className="row align-items-center mb-3">
        {/* Portfolio Selection */}
        <div className="col">
          <MultiSelectInput
            options={portfolios.map((portfolio) => ({
              label: portfolio.portfolio_name,
              value: portfolio.portfolio_id,
            }))}
            selectedValues={selectedPortfolios.map((portfolio) => ({
              label: portfolio.portfolio_name,
              value: portfolio.portfolio_id,
            }))}
            onChange={(selected) =>
              setSelectedPortfolios(
                selected.map((s) => ({
                  portfolio_id: s.value,
                  portfolio_name: s.label,
                }))
              )
            }
            placeholder="Select portfolios..."
            enableAllOption
          />
        </div>

        {/* Symbol Exclusion */}
        <div className="col">
          <MultiSelectInput
            options={availableSymbols.map((symbol) => ({
              label: symbol.symbol,
              value: symbol.symbol,
            }))}
            selectedValues={symbolsToExclude.map((symbol) => ({
              label: symbol.symbol,
              value: symbol.symbol,
            }))}
            onChange={(selected) =>
              setSymbolsToExclude(
                selected.map((s) => ({
                  symbol: s.value,
                  exchanges: "",
                  portfolio_id: "",
                  portfolio_name: "",
                }))
              )
            }
            placeholder="Select symbols to exclude..."
            // enableAllOption
          />
        </div>

        <div className="filter-item align-items-center col-lg-1">
          <select className="form-control" value={currency} onChange={(e) => setCurrency(e.target.value)}>
            <option value="USD" selected>
              USD
            </option>
            <option value="CAD">CAD</option>
            <option value="INR">INR</option>
          </select>
        </div>

        {/* Include Closed Positions Toggle */}
        <div className="col">
          <Form.Check
            type="switch"
            id="include-closed-switch"
            label="Closed positions"
            checked={includeClosed}
            onChange={(e) => setIncludeClosed(e.target.checked)}
          />
        </div>

        {/* Include Interests Toggle */}
        <div className="col">
          <Form.Check
            type="switch"
            id="include-closed-switch"
            label="Interests"
            checked={includeInterests}
            onChange={(e) => setIncludeInterests(e.target.checked)}
          />
        </div>

        {/* Apply Tax Toggle */}
        <div className="col">
          <Form.Check type="switch" id="include-closed-switch" label="Apply Tax (33%)" checked={applyTax} onChange={(e) => setApplyTax(e.target.checked)} />
        </div>

        {/* Calculate Button */}
        <div className="col">
          <Button onClick={handleCalculateXIRR}>Calculate XIRR</Button>
        </div>
      </div>

      {/* Table to display XIRR result */}
      {xirrResult.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th className="numeric-cell">#</th>
              <th>Description</th>
              <th>Currency</th>
              <th className="numeric-cell">Cost Basis ({currency})</th>
              <th className="numeric-cell">Current Value ({currency})</th>
              <th className="numeric-cell">Unrealized P&L ({currency})</th>
              <th className="numeric-cell">Unrealized P&L %</th>
              <th className="numeric-cell">Holding Days</th>
              <th className="numeric-cell">Realized P&L ({currency})</th>
              <th className="numeric-cell">Dividends/Interests ({currency})</th>
              <th className="numeric-cell">ARR %</th>
            </tr>
          </thead>
          <tbody>
            {xirrResult.map((item, index) => (
              <tr key={index}>
                <td className={"numeric-cell" + (index === 0 ? " bold-cell" : "")}>{index > 0 ? index : ""}</td>
                <td className={index === 0 ? "bold-cell" : ""}>{item.symbol}</td>
                <td className={index === 0 ? "bold-cell" : ""}>{item.currency}</td>
                <td className={"numeric-cell" + (index === 0 ? " bold-cell" : "")}>{item.cost_basis?.toLocaleString().replace(/\..+/g, "")}</td>
                <td className={"numeric-cell" + (index === 0 ? " bold-cell" : "")}>{item.current_value?.toLocaleString().replace(/\..+/g, "")}</td>
                <td className={"numeric-cell" + (index === 0 ? " bold-cell" : "")}>{item.unrlz_pnl?.toLocaleString().replace(/\..+/g, "")}</td>
                <td className={"numeric-cell" + (index === 0 ? " bold-cell" : "")} style={{ backgroundColor: colorShade(item.unrlz_pnl_pct, "20") }}>
                  {item.unrlz_pnl_pct ? item.unrlz_pnl_pct.toFixed(2) + "%" : ""}
                </td>
                <td className={"numeric-cell" + (index === 0 ? " bold-cell" : "")}>
                  {index === 0 ? "" : item.hld_days?.toLocaleString().replace(/\..+/g, "")}
                </td>
                <td className={"numeric-cell" + (index === 0 ? " bold-cell" : "")}>{item.realized_pnl?.toLocaleString().replace(/\..+/g, "")}</td>
                <td className={"numeric-cell" + (index === 0 ? " bold-cell" : "")}>{item.dividends?.toLocaleString().replace(/\..+/g, "")}</td>
                <td className={"numeric-cell" + (index === 0 ? " bold-cell" : "")} style={{ backgroundColor: colorShade(item.arr_pct, "60") }}>
                  {item.arr_pct ? item.arr_pct.toFixed(2) + "%" : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default XIRRCalculator;
