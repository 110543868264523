import React, { useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Login } from "./components/Login";
import { useAtom } from "jotai";
import { tokenAtom } from "./state/atoms";
import Portfolio from "./components/Portfolio";
import RealizedPnL from "./components/RealizedPNL";
import Dividends from "./components/Dividends";
import BankInterests from "./components/BankInterests";
import IndiaDividends from "./components/IndiaDividends";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "./logo.png";
import ChartsTab from "./components/ChartsTab";
import SectorChart from "./components/SectorChart";
import EconomicCharts from "./components/EconomicCharts";
import XIRRCalculator from "./components/XIRRCalculator";

const App: React.FC = () => {
  const [token] = useAtom(tokenAtom);
  const loggedIn = Boolean(token.accessToken);
  const [activeTab, setActiveTab] = useState<string>("portfolio");

  // Define tabClicked to store the clicked tab, so that it can set to orange bg color
  const tabClicked = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <div className="container">
      {!loggedIn ? (
        <Login />
      ) : (
        <div>
          <Router>
            {/* <nav>
              <ul>
                <li>
                  <Link to="/">Portfolio</Link>
                </li>
                <li>
                  <Link to="/realized-pnl">Realized P&L</Link>
                </li>
                <li>
                  <Link to="/dividends">Dividends</Link>
                </li>
              </ul>
            </nav> */}
            <Navbar expand="lg" className="p-3 mb-2 bg-light text-white">
              <Container>
                <Navbar.Brand href="/">
                  <img src={logo} alt="logo" width="30" height="30" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link className={activeTab === "portfolio" ? "tab-selected" : "tab-default"} as={Link} to="/" onClick={() => tabClicked("portfolio")}>
                      Portfolio
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "realized-pnl" ? "tab-selected" : "tab-default"}
                      as={Link}
                      to="realized-pnl"
                      onClick={() => tabClicked("realized-pnl")}
                    >
                      Realized P&Ls
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "dividends" ? "tab-selected" : "tab-default"}
                      as={Link}
                      to="dividends"
                      onClick={() => tabClicked("dividends")}
                    >
                      Dividends
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "india-dividends" ? "tab-selected" : "tab-default"}
                      as={Link}
                      to="india-dividends"
                      onClick={() => tabClicked("india-dividends")}
                    >
                      India Dividends
                    </Nav.Link>
                    <Nav.Link className={activeTab === "bankint" ? "tab-selected" : "tab-default"} as={Link} to="bankint" onClick={() => tabClicked("bankint")}>
                      Bank Interests
                    </Nav.Link>
                    <Nav.Link className={activeTab === "charts" ? "tab-selected" : "tab-default"} as={Link} to="charts" onClick={() => tabClicked("charts")}>
                      Charts
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "sector-charts" ? "tab-selected" : "tab-default"}
                      as={Link}
                      to="sector-charts"
                      onClick={() => tabClicked("sector-charts")}
                    >
                      SectorCharts
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "economic-charts" ? "tab-selected" : "tab-default"}
                      as={Link}
                      to="economic-charts"
                      onClick={() => tabClicked("economic-charts")}
                    >
                      EconomicCharts
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "xirr-calc" ? "tab-selected" : "tab-default"}
                      as={Link}
                      to="xirr-calc"
                      onClick={() => tabClicked("xirr-calc")}
                    >
                      XIRR
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
            <Routes>
              <Route path="/" element={<Portfolio />} />
              <Route path="/realized-pnl" element={<RealizedPnL />} />
              <Route path="/dividends" element={<Dividends />} />
              <Route path="/india-dividends" element={<IndiaDividends />} />
              <Route path="/bankint" element={<BankInterests />} />
              <Route path="/charts" element={<ChartsTab />} />
              <Route path="/sector-charts" element={<SectorChart />} />
              <Route path="/economic-charts" element={<EconomicCharts />} />
              <Route path="/xirr-calc" element={<XIRRCalculator />} />
            </Routes>
          </Router>
        </div>
      )}
    </div>
  );
};

export default App;
